// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { ICoords, QueryParameters, QueryResults } from '@models/index';
import { Equipment } from '@equipments/equipments-mgmt/models'
import { Customer } from '@customers/customers-mgmt/models';
import { Device } from '@equipments/devices-mgmt/models';
import { EquipmentModel } from '@configuration/equipment-models/models';
import { EquipmentCategory } from '@configuration/equipment-categories/models';
import { Status } from '@configuration/status/models';
import { EquipmentType } from '@configuration/equipment-types/models';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';
import { EquipmentModelService } from '@configuration/equipment-models/services/equipment-model.service';
import { EquipmentCategoryService } from '@configuration/equipment-categories/services/equipment-category.service';
import { EquipmentTypeService } from '@configuration/equipment-types/services/equipment-type.service';
import { StatusService } from '@configuration/status/services/status.service';
import { SearchStrategy } from '@enums/search-strategy.enum';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'equipment'


@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService,
    private deviceService: DeviceService,
    private equimentModelService: EquipmentModelService,
    private equimentTypeService: EquipmentTypeService,
    private equimentCategoryService: EquipmentCategoryService,
    private statusService: StatusService,



    ) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }

  findLazy(queryParameters: QueryParameters): Observable<QueryResults<Equipment>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ =  this.httpQueryUtils.executeQuery<Equipment>( url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<Equipment>) => {

          // return empty Observable in case of no items
          if( queryResults.items.length === 0 ) return of(queryResults);

          // gets arrays of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(queryResults.items);
          // const requestDetail_2: Observable<Device>[] = this.getDetailObservablesDevice(queryResults.items);
          // const requestDetail_3: Observable<EquipmentModel>[] = this.getDetailObservablesEquipmentModel(queryResults.items);
          // const requestDetail_4: Observable<EquipmentCategory>[] = this.getDetailObservablesEquipmentCategory(queryResults.items);
          // const requestDetail_5: Observable<EquipmentType>[] = this.getDetailObservablesEquipmentType(queryResults.items);
          // const requestDetail_6: Observable<Status>[] = this.getDetailObservablesStatus(queryResults.items);

          // la caña de España!
          return forkJoin([
            ...requestDetail_1
          ])
            .pipe(
              map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                const arrLength = queryResults.items.length;

                const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);
                // const resultDetail_2 = arrayWithDetailData.slice(arrLength, arrLength * 2);
                // const resultDetail_3 = arrayWithDetailData.slice(arrLength * 2, arrLength * 3);
                // const resultDetail_4 = arrayWithDetailData.slice(arrLength * 3, arrLength * 4);
                // const resultDetail_5 = arrayWithDetailData.slice(arrLength * 4, arrLength * 5);
                // const resultDetail_6 = arrayWithDetailData.slice(arrLength * 5);

                queryResults.items
                  .map((item, i) => {

                    item.customer             = resultDetail_1[i];
                    // item.device               = resultDetail_2[i];
                    // item.equipmentModel       = resultDetail_3[i];
                    // item.equipmentCategory    = resultDetail_4[i];
                    // item.equipmentType        = resultDetail_5[i];
                    // item.status               = resultDetail_6[i];

                    return item;
                  });


                return queryResults;
              })
            );
        })
      )

    return  queryResults$;
  }


  find(queryParameters: QueryParameters, searchStrategy?: SearchStrategy): Observable<QueryResults<Equipment>> {

    // console.log(queryParameters);

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ =  this.httpQueryUtils.executeQuery<Equipment>( url, queryParameters);

    if (searchStrategy === SearchStrategy.EAGER) {
      queryResults$ = queryResults$.pipe(
        switchMap((queryResults: QueryResults<Equipment>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          return this.fillRelatedAttributes(queryResults.items)
            .pipe(
              map(() => queryResults))
        })
      )

    }
    return queryResults$;
  }


  findById(id: number, strategy?: SearchStrategy): Observable<Equipment> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<Equipment>(url, {})
      .pipe(
        map(result => result.items[0])
      );


    if (strategy === SearchStrategy.EAGER) {

      queryResults$ = queryResults$
        .pipe(
          switchMap((itemParent: Equipment) => {

            return this.fillRelatedAttributes([itemParent])
              .pipe(
                map((items) => items[0]))
          })
        )
    }
    return queryResults$;

  }

  private fillRelatedAttributes(items: Equipment[]) {

    const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);
    const requestDetail_2: Observable<Device>[] = this.getDetailObservablesDevice(items);
    const requestDetail_3: Observable<EquipmentModel>[] = this.getDetailObservablesEquipmentModel(items);
    const requestDetail_4: Observable<EquipmentCategory>[] = this.getDetailObservablesEquipmentCategory(items);
    const requestDetail_5: Observable<EquipmentType>[] = this.getDetailObservablesEquipmentType(items);
    const requestDetail_6: Observable<Status>[] = this.getDetailObservablesStatus(items);


    // la caña de España!
    return forkJoin([
      ...requestDetail_1, ...requestDetail_2, ...requestDetail_3,
      ...requestDetail_4, ...requestDetail_5, ...requestDetail_6
    ])
      .pipe(
        map((arrayWithDetailData: any) => {

          const arrLength = items.length;

          const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);
          const resultDetail_2 = arrayWithDetailData.slice(arrLength, arrLength * 2);
          const resultDetail_3 = arrayWithDetailData.slice(arrLength * 2, arrLength * 3);
          const resultDetail_4 = arrayWithDetailData.slice(arrLength * 3, arrLength * 4);
          const resultDetail_5 = arrayWithDetailData.slice(arrLength * 4, arrLength * 5);
          const resultDetail_6 = arrayWithDetailData.slice(arrLength * 5);

          items
            .map((item, i) => {

              item.customer = resultDetail_1[i];
              item.device = resultDetail_2[i];
              item.equipmentModel = resultDetail_3[i];
              item.equipmentCategory = resultDetail_4[i];
              item.equipmentType = resultDetail_5[i];
              item.status = resultDetail_6[i];

              return item;
            });


          return items;
        })
      );
  }

  findForMap(queryParameters: QueryParameters): Observable<QueryResults<Equipment>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ = this.httpQueryUtils.executeQuery<Equipment>(url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<Equipment>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          // gets arrays of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(queryResults.items);
          const requestDetail_2: Observable<Device>[] = this.getDetailObservablesDevice(queryResults.items);
          const requestDetail_3: Observable<EquipmentModel>[] = this.getDetailObservablesEquipmentModel(queryResults.items);
          const requestDetail_4: Observable<EquipmentCategory>[] = this.getDetailObservablesEquipmentCategory(queryResults.items);
          const requestDetail_5: Observable<EquipmentType>[] = this.getDetailObservablesEquipmentType(queryResults.items);
          const requestDetail_6: Observable<Status>[] = this.getDetailObservablesStatus(queryResults.items);


          // la caña de España!
          return forkJoin([
            ...requestDetail_1, ...requestDetail_2, ...requestDetail_3,
            ...requestDetail_4, ...requestDetail_5, ...requestDetail_6
          ])
            .pipe(
              map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                const arrLength = queryResults.items.length;

                const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);
                const resultDetail_2 = arrayWithDetailData.slice(arrLength, arrLength * 2);
                const resultDetail_3 = arrayWithDetailData.slice(arrLength * 2, arrLength * 3);
                const resultDetail_4 = arrayWithDetailData.slice(arrLength * 3, arrLength * 4);
                const resultDetail_5 = arrayWithDetailData.slice(arrLength * 4, arrLength * 5);
                const resultDetail_6 = arrayWithDetailData.slice(arrLength * 5);

                queryResults.items
                  .map((item, i) => {

                    item.customer = resultDetail_1[i];
                    item.device = resultDetail_2[i];
                    item.equipmentModel = resultDetail_3[i];
                    item.equipmentCategory = resultDetail_4[i];
                    item.equipmentType = resultDetail_5[i];
                    item.status = resultDetail_6[i];

                    return item;
                  });


                return queryResults;
              })
            );
        })
      )

    return queryResults$;
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: Equipment[]): Observable<Customer>[] {

    return items.map((item: Equipment) => {

      if (item.customerId && item.customerId>0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesDevice(items: Equipment[]): Observable<Device>[] {

    return items.map((item: Equipment) => {

      if (item.deviceId && item.deviceId > 0) {
        return this.deviceService.findById(item.deviceId, SearchStrategy.EAGER);
      } else {
        return of(new Device());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentModel(items: Equipment[]): Observable<EquipmentModel>[] {

    return items.map((item: Equipment) => {

      if (item.equipmentModelId && item.equipmentModelId > 0) {
        return this.equimentModelService.findById(item.equipmentModelId)
      } else {
        return of(new EquipmentModel());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentCategory(items: Equipment[]): Observable<EquipmentCategory>[] {

    return items.map((item: Equipment) => {

      if (item.equipmentCategoryId && item.equipmentCategoryId > 0) {
        return this.equimentCategoryService.findById(item.equipmentCategoryId)
      } else {
        return of(new EquipmentCategory());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentType(items: Equipment[]): Observable<EquipmentType>[] {

    return items.map((item: Equipment) => {

      if (item.equipmentTypeId && item.equipmentTypeId > 0) {
        return this.equimentTypeService.findById(item.equipmentTypeId)
      } else {
        return of(new EquipmentType());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesStatus(items: Equipment[]): Observable<Status>[] {

    return items.map((item: Equipment) => {

      if (item.equipmentTypeId && item.equipmentTypeId > 0) {
        return this.statusService.findById(item.statusId || 0)
      } else {
        return of(new Status());
      }

    });

  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Equipment): Observable<Equipment> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Equipment>(url, entity);
  }


  update(entity: Equipment): Observable<Equipment> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Equipment>(url, entity);
  }


  updateCoords(idEquipment: number, latLng: ICoords): Observable<Equipment> {

    const url = `${BACKEND_API}/${ENTITY}/${idEquipment}/editCoords/${latLng.latitude}/${latLng.longitude}`;

    return this.http.get<Equipment>(url);
  }

  requestPosition(idEquipment: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${idEquipment}/requestPosition`;

    return this.http.get(url);
  }

}
